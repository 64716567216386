// extracted by mini-css-extract-plugin
export const flexStyles = "sectionHeader-module--flexStyles--1gZIj";
export const mb = "sectionHeader-module--mb--8TYCf";
export const sectionHeader = "sectionHeader-module--sectionHeader--3Drxp";
export const blockText = "sectionHeader-module--block-text--1BkX1";
export const blockPhoto = "sectionHeader-module--block-photo--3p5-S";
export const pretitle = "sectionHeader-module--pretitle--gq3Wc";
export const title = "sectionHeader-module--title--sTLS2";
export const specialization = "sectionHeader-module--specialization--13z7f";
export const text = "sectionHeader-module--text--3Bk6S";
export const link = "sectionHeader-module--link--2a-nW";
export const map = "sectionHeader-module--map--3WalW";
export const stylesWithoutImage = "sectionHeader-module--stylesWithoutImage--3yyvO";